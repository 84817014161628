import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { isEmpty, pick } from 'lodash';
import { sessionStorageKeys } from 'constants/localStorageKeys';

type UTMTagKeys = (typeof utmTags)[number];
type UTMParams = Partial<Record<UTMTagKeys, string>>;

const { utmStorageKey } = sessionStorageKeys;
const utmTags = ['utm_source', 'utm_medium', 'utm_campaign'] as const;

const defaultStoreValues = utmTags.reduce((acc, tag) => {
    acc[tag] = '';
    return acc;
}, {} as UTMParams);

export const clearUtmStorage = () => {
    if (!global.window) return;
    sessionStorage.removeItem(utmStorageKey);
};

export const useGetUtmSessionTags = (): UTMParams => {
    if (!global.window) return {};

    const data = sessionStorage.getItem(utmStorageKey);

    return data ? JSON.parse(data) : defaultStoreValues;
};

const useUTMHandler = () => {
    const { replace, query, pathname } = useRouter();

    const handleRouteChange = () => {
        const filteredQuery = pick(query, utmTags);

        if (isEmpty(filteredQuery)) return;

        sessionStorage.setItem(utmStorageKey, JSON.stringify(filteredQuery));

        const modifiedQuery = { ...query };

        utmTags.forEach((tag) => delete modifiedQuery[tag]);

        replace({ pathname, query: modifiedQuery }, undefined, {
            shallow: true,
        });
    };

    useEffect(handleRouteChange, [query]);
};

export default useUTMHandler;
